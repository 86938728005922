<template>
	<div class="my-order">
		<div class="my-title clearfix">
			<span class="">退换/售后</span>
		</div>
		<div class="order-switch">
			<!-- <el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="全部" name="-1"></el-tab-pane>
				<el-tab-pane label="待处理" name="-"></el-tab-pane>
			</el-tabs> -->
			<!-- <div class="search">
				<el-input placeholder="请输入商品名称或订单号" v-model="searchInput" class="input-with-select">
					<el-button slot="append" icon="el-icon-search"></el-button>
				</el-input>
			</div> -->
		</div>
		<!-- 订单列表 -->
		<el-table :data="list.data" stripe>
			<el-table-column prop="order_refund_no" label="售后单号"></el-table-column>
			<el-table-column prop="" label="原订单号"  align="center">
				<template slot-scope="scope">
					<router-link :to="'orderdetails?id='+scope.row.orderData.order_id" class="link-a">
					    {{ scope.row.orderData.order_no }}
					</router-link>
				</template>
			</el-table-column>
			<el-table-column prop="create_time" label="申请时间" align="center"></el-table-column>
			<el-table-column prop="" label="总数量" align="center">
				<template slot-scope="scope">
					{{ scope.row.refundOrderGoods.reduce((sum, e) => sum + Number(e.refund_num), 0) }}
				</template>
			</el-table-column>
			<el-table-column prop="" label="总金额" align="center">
				<template slot-scope="scope">
					{{ scope.row.refundOrderGoods
                        .reduce((sum, e) =>
                            sum + Number(
                                (e.orderGoods.grade_goods_price > 0 ? e.orderGoods.grade_goods_price : e.orderGoods.goods_price)
                                * e.refund_num), 0).toFixed(2) }}
				</template>
			</el-table-column>
            <el-table-column prop="refund_money" label="退款金额" align="center">
            </el-table-column>
			<el-table-column prop="" label="审核状态" align="center">
				<template slot-scope="scope">
					<span class="orange">{{ scope.row.state_text }}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="200" align="center">
				<template slot-scope="scope">
					<router-link :to="{ path: '/myhome/returndetails', query: { orderRefundId: scope.row.order_refund_id }}">
						<el-button class="button-primary-mini" type="primary" size="mini">查看详情</el-button>
					</router-link>

					<el-button v-if="scope.row.state_text == '等待确认中'"
                               @click="comfirmRefund(scope.row)"
                               class="button-warning-mini"
                               type="warning" size="mini" style="margin: 0 0 0 6px;">确定</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<div class="paging">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
				:current-page.sync="currentPage" :page-size="15" layout="prev, pager, next, jumper" :total="list.total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import * as RefundApi from '@/api/refund'
	export default {
		data() {
			return {
				list: [],
				currentPage: 1,
				activeName: 'first',
				searchInput: '',
			};
		},
		created() {
			this.getList()
		},
		methods: {
			getList(){
				let app = this
				RefundApi.list({ state: -1, page: app.currentPage }, { load: false })
				  .then(result => {
				    // 合并新数据
				    app.list = result.data.list
				  })
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val
				this.getList()
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
            comfirmRefund(row) {
                //confirm
                this.$confirm('确定要确认退款吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        this.handleConfirmRefund(row)
                    })
            },
            handleConfirmRefund(row) {
                RefundApi.confirmRefund(row.order_refund_id)
                    .then(() => {
                        this.getList()
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        });
                    })
            }
		}
	};
</script>

<style lang="scss">
	@import "../../assets/css/my-order.scss";
</style>
