import request from '@/utils/request'

// api地址
const api = {
  list: 'refund/list',
  goods: 'refund/goods',
  apply: 'refund/apply',
  detail: 'refund/detail',
  delivery: 'refund/delivery',
    confirmRefund: 'refund/confirmRefund',
}

// 售后单列表
export const list = (param, option) => {
  return request({
      url: api.list,
      method: 'get',
  	params: param,
    })
}

// 订单商品详情
export const goods = (orderGoodsId, param) => {
  return request({
      url: api.goods,
      method: 'get',
  	params: { orderGoodsId, ...param }
    })
}

// 申请售后
export const apply = (orderGoods, data) => {
  return request({
      url: api.apply,
      method: 'post',
	  data: { orderGoods, form: data }
    })
}

// 售后单详情
export const detail = (orderRefundId, param) => {
  return request({
      url: api.detail,
      method: 'get',
  	params: { orderRefundId, ...param }
    })
}

// 用户发货
export const delivery = (orderRefundId, data) => {
  return request({
      url: api.delivery,
      method: 'post',
	  data: { orderRefundId, form: data }
    })
}

export const confirmRefund = (orderRefundId) => {
    return request({
        url: api.confirmRefund,
        method: 'post',
        data: { orderRefundId }
    })
}
